@tailwind base;
@tailwind components;
@tailwind utilities;

/* Globals */
@layer base {
  :root {
    /* Inherited */
    /* --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;

    --background: 0 0% 0%;
    --foreground: 0 0% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;

    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%; */

    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;

    --radius: 0.5rem;
  }

  .dark {
    /* Inherited */
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  html {
    scroll-behavior: smooth;
    /* scroll-snap-type: y proximity; */
  }
  body {
    @apply overflow-x-hidden bg-background font-roboto text-foreground antialiased;
    color-scheme: dark;
    font-size: calc(20px + (40 - 20) * (100vw - 320px) / (2560 - 320));
    font-feature-settings:
      'rlig' 1,
      'calt' 1;

    &::before {
      content: ' ';
      background-image: url('/imgs/bg.jpg');
      @apply fixed -inset-3 -z-1 bg-background/75 bg-cover bg-fixed bg-center bg-blend-overlay blur;
    }
  }

  /* Scroll bar */
  ::-webkit-scrollbar {
    @apply w-4;
  }
  ::-webkit-scrollbar-track {
    @apply bg-transparent;
  }
  ::-webkit-scrollbar-thumb {
    @apply rounded-full bg-black/30 bg-clip-padding transition-all;
    border: 4px solid transparent;

    &:hover {
      @apply bg-black/50;
    }
  }

  :is(.dark) {
    ::-webkit-scrollbar-thumb {
      @apply bg-orange-400/50;

      &:hover {
        @apply bg-orange-400;
      }
    }
  }
}

/* Components */
@layer components {
  .main-content {
    @apply relative flex-grow px-2 pb-3;

    & > *:first-child:is(.snap-start, .snap-center, .snap-end) {
      @apply scroll-mt-[calc(100dvh_-_var(--nav-height))];
    }
  }

  .frame {
    /* DEV: original 'backdrop-blur-sm bg-neutral-900/75', just to increase the perfomance during development  p-3 */
    @apply border border-solid border-neutral-600 bg-neutral-900 bg-none;

    &.frame-card {
      @apply rounded-md p-3;
    }
    &.frame-item {
      @apply rounded bg-white/10 p-2;
    }
  }

  @screen tablet {
    .main-content {
      @apply px-3;
    }
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
  /* Overflow */
  .overflow-overlay {
    overflow: overlay;
  }
  .overflow-y-overlay {
    overflow-y: overlay;
  }
  .overflow-x-overlay {
    overflow-x: overlay;
  }

  /* Shadow */
  .custom-shadow-sm {
    box-shadow:
      0px 0.1px 0.1px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      0px 0.2px 0.2px -1.2px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      0.1px 0.6px 0.7px -2.3px var(--tw-shadow-color, rgba(0, 0, 0, 1));
  }
  .custom-shadow-md {
    box-shadow:
      0.1px 0.4px 0.5px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      0.4px 1.4px 1.7px -0.7px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      1px 3.3px 4.1px -1.4px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      2.4px 7.9px 9.8px -2.1px var(--tw-shadow-color, rgba(0, 0, 0, 1));
  }
  .custom-shadow-lg {
    box-shadow:
      0.1px 0.4px 0.5px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      0.8px 2.6px 3.2px -0.3px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      1.5px 4.9px 6.1px -0.6px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      2.4px 7.8px 9.7px -0.9px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      3.7px 12.1px 15px -1.2px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      5.7px 18.6px 23.1px -1.5px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      8.5px 27.8px 34.4px -1.8px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      12.4px 40.5px 50.2px -2.1px var(--tw-shadow-color, rgba(0, 0, 0, 1));
  }
  .custom-shadow-sm-dark {
    box-shadow:
      0px 0.1px 0.1px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      0px 0.2px 0.2px -1.2px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      0.1px 0.6px 0.7px -2.3px var(--tw-shadow-color, rgba(0, 0, 0, 1));
  }
  .custom-shadow-md-dark {
    box-shadow:
      -0.1px 0.6px 0.8px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -0.3px 2.4px 3.3px -0.2px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -0.5px 4.4px 6px -0.5px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -0.8px 7.9px 10.7px -0.7px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -1.5px 14.2px 19.3px -1px var(--tw-shadow-color, rgba(0, 0, 0, 1));
  }
  .custom-shadow-lg-dark {
    box-shadow:
      -0.1px 0.3px 0.3px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -0.2px 1.1px 1.2px -0.3px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -0.3px 1.8px 2px -0.6px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -0.5px 2.8px 3.1px -0.9px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -0.7px 4.2px 4.6px -1.3px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -1px 6.1px 6.6px -1.6px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -1.5px 8.8px 9.6px -1.9px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -2.1px 12.4px 13.5px -2.2px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -2.9px 17.2px 18.7px -2.5px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -4px 23.3px 25.4px -2.8px var(--tw-shadow-color, rgba(0, 0, 0, 1));
  }

  /* Drop shadow */
  .custom-drop-shadow {
    filter: drop-shadow(5px 5px 13px #ffd33d);
  }

  /* Text shadow */
  .text-shadow {
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  }
  .text-shadow-dark {
    text-shadow: var(--color-accent) 1px 0 10px;
  }
}

.comingsoon {
  display: grid;
  background: linear-gradient(to left, #6ab6f8, #bf75d3, #ec764f);
  border-radius: 0.5rem;
  box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.6);
  font-size: clamp(1rem, 10vw, 2rem);
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  transform: rotateZ(-20deg);
  /* animation: anim 2s linear infinite alternate-reverse; */
  @apply my-6 animate-wiggle p-2 md:p-6;
}

@keyframes anim {
  to {
    transform: rotateZ(20deg);
  }
}
