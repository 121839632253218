.root {
  @apply absolute inset-x-2 top-2 rounded-md;
  @apply flex items-center gap-4;
  @apply shadow-theme-blue/40 ring-theme-blue/60 bg-neutral-700/25 p-4 shadow-md ring-1 backdrop-blur;
  @apply text-xl font-normal;
}
.logo {
  @apply -m-2 flex items-center gap-2 text-sm md:text-lg;

  & > img {
    width: 24px;
    height: 24px;
  }
}

@screen md {
  .logo {
    & > img {
      width: 42px;
      height: auto;
    }
  }
}
